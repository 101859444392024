<template>
  <div class="city_page">
    <div class="city_box">
      <el-tag
        v-for="(item, index) in quickAreas"
        :key="index"
        closable
        :disable-transitions="false"
        @close="handleClose(index)"
        @click="handleClick(index)">
        <i v-if="item.checked" class="el-icon-location-outline"></i>
        {{ item.value }}
      </el-tag>
      <el-cascader
        v-show="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        :options="cityData"
        :props="{
          value: 'Name',
          label: 'Name',
          checkStrictly: true
        }"
        popper-class="select_city_popper"
        @change="cityChange"
        @visible-change="cityVisibleChange"
      ></el-cascader>
      <el-button v-show="!inputVisible" class="btn_add" @click="showInput">+</el-button>
    </div>
    <!-- <h5>空气质量：优污染指数：23</h5> -->
  </div>
</template>
<script>
import {mapState} from 'vuex'
import {loadData, getUserInfo} from '@/util/index'

export default {
  name: "SelectCity",
  data() {
    return {
      userMobile: '', // 用户手机号，用于提取本地对应账号的存储值
      quickAreas: [], // 快捷区域数据集合
      inputVisible: false,
      inputValue: '',
      cityData: [] // 城市三级列表
    }
  },
  computed: {
    ...mapState({
      areaTree: (state) => state.areaTree
    })
  },
  mounted() {
    if (!this.areaTree || this.areaTree.length === 0) {
      loadData(this, (res) => {
        this.$store.dispatch('setArea', res).then((dataFormatter) => {
          this.cityData = this.areaTree[0].children
        })
      }, 2)
    } else {
      this.cityData = this.areaTree[0].children
    }

    // 获取用户手机号
    this.userMobile = getUserInfo().Mobile

    // 获取本地快捷区域数据集合
    const quickAreasGather = localStorage.getItem('quickAreasGather')
    if (quickAreasGather) {
      this.quickAreas = JSON.parse(quickAreasGather)[this.userMobile] || []
      // this.handleClick(0) // 默认第一个为当前选择区域
    } else {
      this.handleClick(-1)
    }
  },
  methods: {
    /**
     * @description 删除快捷区域
     * @param {Number} index 快捷区域所在索引值
     */
    handleClose(index) {
      this.quickAreas.splice(index, 1)
    },

    /**
     * @description 选择快捷区域
     * @param {Number} index 快捷区域所在索引值
     */
    handleClick(index) {
      this.$emit('on-change', index >= 0 ? this.quickAreas[index] : null, 1)
      this.quickAreas = this.quickAreas.map((item, i) => {
        if (index === i) {
          item.checked = true
        } else {
          item.checked = false
        }
        return item
      })
    },
    /**
     * @description 显示添加快捷区域按钮
     */
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    /**
     * @description 选择区域
     * @param { Array } value 选择区域索引值
     */
    cityChange(value) {
      // this.inputVisible = false
      // this.inputValue = ''
    },

    /**
     * @description 联级选择器
     */
    cityVisibleChange(visible) {
      if (!visible && this.inputValue) {
        const inputValue = this.inputValue.join('/')
        if (inputValue) {
          const checkedObj = this.$refs.saveTagInput.getCheckedNodes()[0].data
          this.quickAreas.push({
            value: inputValue,
            areaId: checkedObj.Id,
            code: checkedObj.Code,
            level: checkedObj.Typ,
            name: this.inputValue[this.inputValue.length - 1]
          })
        }
        // this.handleInputConfirm()
        this.inputVisible = false
        this.inputValue = ''
      }
    }
  },
  watch: {
    'quickAreas': {
      handler: function (val) {
        const quickAreasGather = localStorage.getItem('quickAreasGather') ? JSON.parse(localStorage.getItem('quickAreasGather')) : {}
        quickAreasGather[this.userMobile] = val
        localStorage.setItem('quickAreasGather', JSON.stringify(quickAreasGather))
      },
      deep: true
    }
  }
}
</script>
<style lang="scss">
.city_page {
  margin: 0 0 10px;
  height: 4vh;

  h5 {
    font-size: 11px;
    color: #B2F2F6;
    padding: 0 35px;
    font-weight: normal;
    margin: 0;
  }
}

.city_box {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .el-tag {
    background-color: transparent;
    border: 1px solid #24FFFC;
    font-size: 14px;
    color: #B2F2F6;
    border-radius: 0;
    height: 32px;
    line-height: 30px;
    padding: 0 15px;
    margin-bottom: 8px;
    cursor: pointer;

    .el-icon-close {
      font-size: 11px;
      color: #B2F2F6;

      &:hover {
        background-color: #22c6c4;
      }
    }
  }

  .el-tag + .el-tag {
    margin-left: 12px;
  }

  .btn_add {
    height: 32px;
    border: 1px solid #24FFFC;
    width: 100px;
    border-radius: 0;
    background-color: transparent;
    font-size: 20px;
    padding: 0;
    line-height: 32px;
    margin: 0 0 8px 12px;
    color: #B2F2F6;
  }

  .el-cascader {
    margin: 0 0 8px 12px;
    line-height: initial;

    .el-input .el-input__inner:focus,
    .el-cascader .el-input.is-focus .el-input__inner {
      border-color: #B2F2F6;
    }

    .el-input .el-input__inner:focus,
    .el-input.is-focus .el-input__inner {
      border-color: #24FFFC;
    }

    .el-input__inner {
      border-radius: 0;
      background-color: transparent;
      height: 32px;
      font-size: 14px;
      color: #B2F2F6;
      border-color: #24FFFC;

      &:focus {
        border-color: #24FFFC;
      }
    }
  }
}

.select_city_popper {
  background-color: #03232A;
  border-color: #24FFFC;
  border-radius: 3px;

  .el-cascader-node.in-active-path,
  .el-cascader-node.is-active,
  .el-cascader-node.is-selectable.in-checked-path {
    color: #24FFFC;
  }

  .el-cascader-node:not(.is-disabled):focus, .el-cascader-node:not(.is-disabled):hover {
    background-color: rgba($color: #24FFFC, $alpha: 0.1);
  }

  .el-cascader-menu {
    color: rgba($color: #24FFFC, $alpha: 0.7);
    border-right-color: #24FFFC;
  }

  &[x-placement^=bottom] .popper__arrow {
    border-bottom-color: #24FFFC;
  }

  &[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: #03232A;
  }

  .el-radio__inner {
    background-color: transparent;
    border-color: #24FFFC;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #24FFFC;
    background-color: #24FFFC;
  }
}
</style>
