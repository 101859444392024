var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"center_block"},[_c('select-city',{on:{"on-change":_vm.selectCityChange}}),_c('center-data',{attrs:{"sum-data":_vm.sumData,"loading":_vm.loading}}),_c('div',{staticClass:"map_block"},[(_vm.contentData.level !== 'district' & _vm.contentData.level!=='3')?_c('dot-map',{attrs:{"content-data":_vm.contentData,"online-device":_vm.onlineDevice,"top-list":_vm.topList,"sum-data":_vm.sumData,"board-list":_vm.boardList,"isShowExceed":_vm.isShowExceed,"isShowEmphasisDot":_vm.emphasisSomeDot},on:{"echartsMapClick":_vm.areaCodeChange}}):_vm._e(),(_vm.contentData.level === 'district'|| _vm.contentData.level==='3')?_c('dot-map-district',{attrs:{"content-data":_vm.contentData,"online-device":_vm.onlineDevice,"top-list":_vm.topList,"sum-data":_vm.sumData,"county-level-data":_vm.countyLevel,"board-list":_vm.boardList,"isShowExceed":_vm.isShowExceed,"isShowEmphasisDot":_vm.emphasisSomeDot}}):_vm._e()],1),_c('div',{staticClass:"bottom_block"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"bottom_block clickable"},[_c('online-platform',{attrs:{"content-data":{
          monthData:_vm.sumData.monthGroupByDates,
          dateData: _vm.sumData.onlineDeviceCountByDate,
          hoursData: _vm.topList.onlineAndComplianceDeviceCountByHour
      },"showHours":_vm.showHoursData0},nativeOn:{"click":function($event){return _vm.switchHoursAndDate(0)}}}),_c('center-reach-rate',{attrs:{"content-data":{
          monthData:_vm.sumData.monthGroupByDates,
          dateData: _vm.sumData.complianceDeviceRatioByDate,
          hoursData: _vm.topList.onlineAndComplianceDeviceCountByHour
      },"showHours":_vm.showHoursData1},nativeOn:{"click":function($event){return _vm.switchHoursAndDate(1)}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }