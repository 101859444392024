<template>
  <div class="install_number_page" v-loading="loading">
    <dec-block-title>安装数TOP5(台)</dec-block-title>
    <dv-charts :option="option"/>
  </div>
</template>

<script>
import decBlockTitle from '../decorations/blockTitle2'
// import { queryToMD5 } from "@/utils/md5_query"

export default {
  name: 'installNumber',
  components: {
    decBlockTitle
  },
  props: {
    onlineDevice: {
      type: Array,
      default: () => {
        return []
      }
    },
    showMore: {
      type: Boolean,
      default: () => {
        return true
      }
    },
  },
  data() {
    return {
      option: {},
      installNumber: [],
      loading: true
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    showMore: {
      handler: function () {
        this.init()
      }
    },
    onlineDevice: {
      handler: function () {
        this.init()
      },
      deep: true
    }
  },
  methods: {
    init() {
      this.loading = true
      if (!this.onlineDevice) {
        return
      }
      if (this.onlineDevice.length === 0) {
        this.option = {}
      }
      this.getData()

    },
    getData() {
      if (!this.showMore) {
        this.option = {
          // color: '#fff',
          xAxis: {
            min: 0,
            axisLine: {
              style: {
                stroke: '#324D4D',
                lineDash: [5, 2]
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 9,
                fill: '#D0D0D0'
              }
            },
            splitLine: {
              stroke: 'red',
              lineWidth: 1
            },
            data: this.onlineDevice.filter((item, index) => index < 5).map(item => {
              return item.N
            })
          },
          yAxis: {
            min: 0,
            data: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 9,
                fill: '#D0D0D0'
              }
            },
            splitLine: {
              style: {
                stroke: '#324D4D',
                lineWidth: 1,
                lineDash: [5, 2]
              }
            }
          },
          series: [
            {
              xAxisIndex: 0,
              yAxisIndex: 0,
              data: this.onlineDevice.filter((item, index) => index < 5).map(item => {
                return item.V
              }),
              type: 'bar',
              barWidth: 20,
              gradient: {
                color: ['rgba(76,198,255,1)', 'rgba(75,189,255,0.05)']
              }
            }
          ],
          grid: {
            left: 30,
            top: 20,
            right: 0,
            bottom: 20
          }
        }
      } else {
        this.option = {
          // color: '#fff',
          yAxis: {
            axisLine: {
              style: {
                stroke: '#324D4D',
                lineDash: [5, 2]
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 11,
                fill: '#D0D0D0',
                rotate: 45
              }
            },
            splitLine: {
              show: false
            },
            data: this.onlineDevice.map(item => {
              return item.N || ''
            }).reverse()
          },
          xAxis: {
            min: 0,
            data: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 9,
                fill: '#D0D0D0'
              }
            },
            splitLine: {
              style: {
                stroke: '#324D4D',
                lineWidth: 1,
                lineDash: [5, 2]
              }
            }
          },
          series: [
            {
              xAxisIndex: 0,
              yAxisIndex: 0,
              data: this.onlineDevice.map(item => {
                return item.V || ''
              }).reverse(),
              type: 'bar',
              barWidth: 20,
              gradient: {
                color: ['rgba(76,198,255,1)', 'rgba(75,189,255,0.05)']
              }
            }
          ],
          grid: {
            right: 5,
            left: 45,
            bottom: 20,
            top: 30
          }
        }
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.install_number_page {
  width: 100%;
  // height: 33%;
  box-sizing: border-box;

  .dv-charts-container {
    height: calc(100% - 45px);
  }
}
</style>
