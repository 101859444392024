<template>
  <div class="center_block">
    <select-city @on-change="selectCityChange"></select-city>
    <center-data
      :sum-data="sumData"
      :loading="loading"/>
    <div class="map_block">
      <dot-map
        :content-data="contentData"
        :online-device="onlineDevice"
        :top-list="topList"
        :sum-data="sumData"
        :board-list="boardList"
        :isShowExceed="isShowExceed"
        :isShowEmphasisDot="emphasisSomeDot"
        @echartsMapClick="areaCodeChange"
        v-if="contentData.level !== 'district' & contentData.level!=='3'"
      ></dot-map>
      <dot-map-district
        :content-data="contentData"
        :online-device="onlineDevice"
        :top-list="topList"
        :sum-data="sumData"
        :county-level-data="countyLevel"
        :board-list="boardList"
        :isShowExceed="isShowExceed"
        :isShowEmphasisDot="emphasisSomeDot"
        v-if="contentData.level === 'district'|| contentData.level==='3'"
      ></dot-map-district>
    </div>
    <div class="bottom_block">
      <div class="bottom_block clickable" v-loading="loading">
        <online-platform
          @click.native="switchHoursAndDate(0)"
          :content-data="{
            monthData:sumData.monthGroupByDates,
            dateData: sumData.onlineDeviceCountByDate,
            hoursData: topList.onlineAndComplianceDeviceCountByHour
        }"
          :showHours="showHoursData0"
        ></online-platform>
        <center-reach-rate
          @click.native="switchHoursAndDate(1)"
          :content-data="{
            monthData:sumData.monthGroupByDates,
            dateData: sumData.complianceDeviceRatioByDate,
            hoursData: topList.onlineAndComplianceDeviceCountByHour
        }"
          :showHours="showHoursData1"
        ></center-reach-rate>
      </div>
    </div>
  </div>
</template>

<script>
// import { queryToMD5 } from "@/utils/md5_query"
import selectCity from './city'
import onlinePlatform from './onlinePlatform'
import centerReachRate from './centerReachRate'
import dotMap from './sctterMap'
import dotMapDistrict from './sctterMapQu'
import centerData from "./centerData";
import digitalFlop from '../decorations/digitalFlop'
import {mapState} from 'vuex'
import {isEmptyObj} from "@/util";

export default {
  name: 'center',
  components: {
    selectCity,
    digitalFlop,
    onlinePlatform,
    centerReachRate,
    dotMap,
    dotMapDistrict,
    centerData
  },
  props: ['contentData', 'sumData', 'onlineDevice', 'topList', 'boardList', 'countyLevel'],
  watch: {
    sumData: {
      handler: function () {
        this.init()
      },
      deep: true
    },
    onlineDevice: {
      handler: function () {
        this.init()
      },
      deep: true
    },
    topList: {
      handler: function () {
        this.init()
      },
      deep: true
    },
    boardList: {
      handler: function () {
        this.init()
      },
      deep: true
    }
  },
  data() {
    return {
      timer: null, // 时间对象
      curArea: null, // 当前区域
      isShowExceed: false,
      emphasisSomeDot: {
        show: false,
        type: ''
      },
      showHoursData0: 0,
      showHoursData1: 0,
      loading: true
    }
  },
  computed: {
    ...mapState(['districtInfoType'])
  },
  methods: {
    init() {
      this.loading = true
      if (isEmptyObj(this.sumData) || isEmptyObj(this.onlineDevice) || isEmptyObj(this.topList) || isEmptyObj(this.boardList))
        return
      this.loading = false
    },
    switchHoursAndDate(type) {
      switch (type) {
        case 0:
          this.showHoursData0 = (this.showHoursData0 + 1) % 3
          break
        case 1:
          this.showHoursData1 = (this.showHoursData1 + 1) % 3
          break
      }
    },
    switchEmphasisDot(type, e) {
      const centerTop = document.getElementById('center-top')
      const clickedList = centerTop.getElementsByClassName('switch-emphasis-dot clicked')
      const clickableList = centerTop.getElementsByClassName('clickable')
      const index = Array.from(clickableList).indexOf(e.currentTarget)
      for (let i = 1; i < clickableList.length; i++) {
        if (i !== index) {
          clickableList[i].classList.remove('clicked')
        }
      }
      e.currentTarget.classList.toggle('clicked')
      this.emphasisSomeDot = {
        show: clickedList.length ? true : false,
        type: type
      }
      console.log(this.emphasisSomeDot)
    },
    /**
     * @description 选择区域
     * @param {Object} area 区域
     */
    selectCityChange(area, n) {
      this.curArea = Object.assign({}, area, this.contentData)
      console.log(this.curArea)
      this.$emit('cityChange', area)
    },
    areaCodeChange(param) {
      this.$emit('cityChange', param)
    },
    switchShowExceed() {
      this.isShowExceed = !this.isShowExceed
    },
    handleLeftContentClick(type, e) {
      if (this.contentData.level === 'country') return
      e.currentTarget.classList.toggle('clicked')
      if (this.$store.state.districtInfoType !== type) {
        this.showMore = true
      } else {
        this.showMore = !this.showMore
      }
      this.$store.commit('setShowMoreDistrictInfo', {isShow: this.showMore, type: this.showMore ? type : 0})
    }
  }
}
</script>

<style lang="scss">
.center_block {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  height: 90vh;

  .map_block {
    flex: 1;
    height: 60vh;
    // margin-top: 14px;
  }

  .data_box {
    display: flex;
    height: 7vh;
    align-items: center;

    ul {
      flex: 1;
      display: flex;
      justify-content: space-between;
      // flex-wrap: nowrap;
      // padding-left: 94px;
      margin: 0;

      li {
        // width: 12.5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10px;

        h3 {
          font-size: 10px;
          color: #B2F2F6;
          margin: 0 0 10px;
          font-weight: normal;
          text-align: center;
        }
      }
    }

    .clicked {
      background-color: #0e265d;
      opacity: 0.7;
    }

    .clickable:hover {
      cursor: pointer;
    }
  }

  .bottom_block {
    display: flex;
    height: 13vh;

    > div {
      flex: 1;
    }
  }

}
</style>
