<template>
  <div class="dec_digital_flop">
    <span v-if="title">{{ title }}</span>
    <dv-digital-flop :config="mergerConfig" :style="styles" />
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default() {
        return {}
      }
    },
    styles: {
      type: Object,
      default() {
        return {
          width: '146px',
          height: '30px'
        }
      }
    }
  },
  data() {
    return {
      defaultConfig: {
        number: [0],
        textAlign: 'center',
        style: {
          fontSize: 10,
          fill: '#1BFFE4',
          fontFamily: 'DIN-Medium'
        }
      }
    }
  },
  computed: {
    mergerConfig() {
      const config = JSON.parse(JSON.stringify(this.config))
      const defaultConfig = JSON.parse(JSON.stringify(this.defaultConfig))
      return Object.assign(defaultConfig, config)
    }
  },
  mounted() {

  }
}
</script>
<style lang="scss" scoped>
.dec_digital_flop {
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #fff;

  span {
    margin-right: 8px;
  }

}
</style>
