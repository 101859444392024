<template>
  <div class="data_box" v-loading="loading">
    <!-- <div class="install_number_box">
      <h3>安装点总数[个]</h3>
      <digital-flop :config="{ number: [centerData.totalLocaleCount], style: { fontSize: 40, fill: '#CDA52B', fontWeight: 'bold' } }" :styles="{ width: '100%', height: '35px'}"></digital-flop>
    </div> -->
    <ul id="center-top">
      <li class="clickable switch-label-line" :class="{ 'clicked': this.districtInfoType === 3 }"
          @click="handleLeftContentClick(3, $event)">
        <h3>监测点总数[个]</h3>
        <count-to
          class="center-data-count"
          :start-val="0"
          :end-val="sumData.totalDeviceInstalledCount"
          :duration="sumData.totalDeviceInstalledCount /100"
        />
        <!--        <digital-flop-->
        <!--          :config="{ number: [sumData.totalDeviceInstalledCount], style: { fontSize: 20, fill: '#CDA52B' } }"-->
        <!--          :styles="{ width: '100%', height: '34px', textAlign: 'center'}"></digital-flop>-->
      </li>
      <li class="clickable switch-emphasis-dot" @click="switchEmphasisDot('online', $event)">
        <h3>在线数[台]</h3>
        <count-to
          class="center-data-count"
          :start-val="0"
          :end-val="sumData.onlineDeviceCount"
          :duration="sumData.onlineDeviceCount/100"
        />
        <!--        <digital-flop-->
        <!--          :config="{ number: [sumData.onlineDeviceCount], style: { fontSize: 20, fill: '#CDA52B' } }"-->
        <!--          :styles="{ width: '100%', height: '34px', textAlign: 'center'}"></digital-flop>-->
      </li>
      <li class="clickable switch-emphasis-dot" @click="switchEmphasisDot('normal', $event)">
        <h3>达标数[台]</h3>
        <count-to
          class="center-data-count"
          :start-val="0"
          :end-val="sumData.complianceDeviceCount"
          :duration="sumData.complianceDeviceCount/100"
        />
        <!--        <digital-flop-->
        <!--          :config="{ number: [sumData.complianceDeviceCount], style: { fontSize: 20, fill: '#CDA52B' } }"-->
        <!--          :styles="{ width: '100%', height: '34px', textAlign: 'center'}"></digital-flop>-->
      </li>
      <li class="clickable switch-emphasis-dot" @click="switchEmphasisDot('exceed', $event)">
        <h3>超标数[台]</h3>
        <count-to
          class="center-data-count"
          :start-val="0"
          :end-val="sumData.notComplianceDeviceCount"
          :duration="sumData.notComplianceDeviceCount/100"
        />
        <!--        <digital-flop-->
        <!--          :config="{ number: [sumData.notComplianceDeviceCount], style: { fontSize: 20, fill: '#CDA52B' } }"-->
        <!--          :styles="{ width: '100%', height: '34px', textAlign: 'center'}"></digital-flop>-->
      </li>
      <li>
        <h3>PM2.5年减排[Kgs]</h3>
        <count-to
          class="center-data-count"
          :start-val="0"
          :end-val="sumData.redPm25Amount"
          :duration="sumData.redPm25Amount/100"
        />
        <!--        <digital-flop-->
        <!--          :config="{ number: [sumData.redPm25Amount], style: { fontSize: 16, fill: '#CDA52B' } }"-->
        <!--          :styles="{ width: '100%', height: '28px', textAlign: 'center'}"></digital-flop>-->
      </li>
      <li>
        <h3>PM10年减排[Kgs]</h3>
        <count-to
          class="center-data-count"
          :start-val="0"
          :end-val="sumData.redPm10Amount"
          :duration="sumData.redPm10Amount/100"
        />
        <!--        <digital-flop-->
        <!--          :config="{ number: [sumData.redPm10Amount], style: { fontSize: 16, fill: '#CDA52B' } }"-->
        <!--          :styles="{ width: '100%', height: '28px', textAlign: 'center'}"></digital-flop>-->
      </li>
      <li>
        <h3>非甲烷总烃年减排[Kgs]</h3>
        <count-to
          class="center-data-count"
          :start-val="0"
          :end-val="sumData.redVocsAmount"
          :duration="sumData.redVocsAmount/100"
        />
        <!--        <digital-flop-->
        <!--          :config="{ number: [sumData.redVocsAmount], style: { fontSize: 16, fill: '#CDA52B' } }"-->
        <!--          :styles="{ width: '100%', height: '28px', textAlign: 'center'}"></digital-flop>-->
      </li>
      <li>
        <h3>油烟年减排[Kgs]</h3>
        <count-to
          class="center-data-count"
          :start-val="0"
          :end-val="sumData.redEmissionAmount"
          :duration="sumData.redEmissionAmount/100"
        />
        <!--        <digital-flop-->
        <!--          :config="{ number: [sumData.redEmissionAmount], style: { fontSize: 16, fill: '#CDA52B' } }"-->
        <!--          :styles="{ width: '100%', height: '28px', textAlign: 'center'}"></digital-flop>-->
      </li>
    </ul>
  </div>
</template>

<script>
import DigitalFlop from "@/view/bigscreen/components/decorations/digitalFlop.vue";
import {mapState} from "vuex";
import {isEmptyObj} from "@/util";
import countTo from 'vue-count-to'

export default {
  name: 'CenterData',
  components: {DigitalFlop, countTo},
  computed: {
    ...mapState(['districtInfoType'])
  },
  props: ['sumData'],
  data() {
    return {
      loading: false,
    }
  },

  methods: {
    handleLeftContentClick(type) {
      if (this.dataObj.level === 'country') return
      if (this.$store.state.districtInfoType !== type) {
        this.showMore = true
      } else {
        this.showMore = !this.showMore
      }
      this.$store.commit('setShowMoreDistrictInfo', {isShow: this.showMore, type: this.showMore ? type : 0})
    },
    switchEmphasisDot(type, e) {
      const centerTop = document.getElementById('center-top')
      const clickedList = centerTop.getElementsByClassName('switch-emphasis-dot clicked')
      const clickableList = centerTop.getElementsByClassName('clickable')
      const index = Array.from(clickableList).indexOf(e.currentTarget)
      for (let i = 1; i < clickableList.length; i++) {
        if (i !== index) {
          clickableList[i].classList.remove('clicked')
        }
      }
      e.currentTarget.classList.toggle('clicked')
      this.emphasisSomeDot = {
        show: clickedList.length ? true : false,
        type: type
      }
      console.log(this.emphasisSomeDot)
    },
  }
}
</script>

<style lang="scss">
.reach_rate_page {
  width: 100%;
  // height: 33%;
  box-sizing: border-box;

  .dv-charts-container {
    height: calc(100% - 45px);
  }
}

.center-data-count {
  font-size: 20px;
  color: #CDA52B;
  width: 100%;
  height: 34px;
  text-align: center;
}
</style>
