<template>
  <div class="region_compare_dis" v-loading="loading">
    <dec-block-title>报警次数对比</dec-block-title>
    <ul>
      <li>
        <dv-charts
          :key="key"
          :option="option"
          style="width: 300px; height: 300px"
        />
        <span>报警次数对比</span>
      </li>
    </ul>
  </div>
</template>

<script>
import decBlockTitle from "../decorations/blockTitle2";
// import { queryToMD5 } from "@/utils/md5_query"

export default {
  name: "regionCompare",
  components: {
    decBlockTitle,
  },
  props: ["countyLevelData"],
  data() {
    return {
      key: 1,
      option: {},
      key2: 1,
      loading: true
    };
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      if (!this.countyLevelData)
        return
      this.setOption()
      this.loading = false
    },
    setOption() {
      this.key++;
      const top10 =
        this.countyLevelData.alarmCountByLocaleRecent30DaysTop10 ||
        [];
      const datas = top10.map((v) => {
        const itm = {
          name: v.LocaleName,
          value: v.AlarmCount,
        };
        return itm;
      });
      this.option = {
        series: [
          {
            type: "pie",
            data: datas,
            // insideLabel: {
            //   show: true,
            //   formatter: "${name}",
            // },
            outsideLabel: {
              //   show: false,
              labelLineBendGap: 5,
              labelLineEndLength: 5,
              formatter: (item) => {
                const idx = item.name.indexOf('-烟道')
                return idx === -1 ? item.name.substr(0, 8) : item.name.substr(0, idx)
              }
            },
            roseType: true,
          },
        ],
      };
    },
  },
  watch: {
    countyLevelData: {
      handler: function () {
        this.init()
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.region_compare_dis {
  width: 100%;
  // height: 33%;
  box-sizing: border-box;

  ul,
  li {
    padding: 0;
    margin: 0;
  }

  ul {
    height: calc(100% - 45px);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      > span {
        font-size: 13px;
        color: #b2f2f6;
        text-align: center;
      }
    }
  }
}
</style>
