<template>
  <div id="data-view">
    <top-header/>
    <dv-loading v-if="bigScreenLoading">Loading...</dv-loading>
    <div class="main-content" v-else>
      <p class="back" @click.stop="backTop" v-show="dataObj.level !== 'country'">返回</p>
      <div class="block-left-content" v-if="dataObj.level !== 'district' & dataObj.level !== '3'">
        <div class="content-box">
          <online-rate
            class="content-box-item"
            :show-more=buttonShowMore
            :top-list="allTopList"
            :online-device="onlineDeviceData"
            :key="currentKey"
            @click.native="handleLeftContentClick(1)"
          ></online-rate>
          <el-button
            type="text"
            icon="el-icon-caret-bottom"
            class="foldStatusButton button"
            v-show="!buttonShowMore"
            @click="unfoldContentItem($event)"
          ></el-button>
          <el-button
            type="text"
            icon="el-icon-caret-top"
            class="foldStatusButton button"
            v-show="buttonShowMore"
            @click="foldContentItem($event)"
          ></el-button>
        </div>
        <div class="content-box">
          <reach-rate
            class="content-box-item"
            :show-more=buttonShowMore
            :top-list="allTopList"
            :online-device="onlineDeviceData"
            :key="currentKey"
            @click.native="handleLeftContentClick(2)"
          ></reach-rate>
          <el-button
            type="text"
            icon="el-icon-caret-bottom"
            class="foldStatusButton button"
            v-show="!buttonShowMore"
            @click="unfoldContentItem($event)"
          ></el-button>
          <el-button
            type="text"
            icon="el-icon-caret-top"
            class="foldStatusButton button"
            v-show="buttonShowMore"
            @click="foldContentItem($event)"
          ></el-button>
        </div>
        <div class="content-box">
          <install-number
            class="content-box-item"
            :showMore="buttonShowMore"
            :online-device="onlineDeviceData.device"
            :key="currentKey"
            @click.native="handleLeftContentClick(3)"
          ></install-number>
          <el-button
            type="text"
            icon="el-icon-caret-bottom"
            class="foldStatusButton button"
            v-show="!buttonShowMore"
            @click="unfoldContentItem($event)"
          ></el-button>
          <el-button
            type="text"
            icon="el-icon-caret-top"
            class="foldStatusButton button"
            v-show="buttonShowMore"
            @click="foldContentItem($event)"
          ></el-button>
        </div>
        <div class="content-box">
          <emission-reduce
            class="content-box-item"
            :show-more="buttonShowMore"
            :all-top-list="allTopList"
            :online-device="onlineDeviceData"
            :key="currentKey"
            @click.native="handleLeftContentClick(4)"
          ></emission-reduce>
          <el-button
            type="text"
            icon="el-icon-caret-bottom"
            class="foldStatusButton button"
            v-show="!buttonShowMore"
            @click="unfoldContentItem($event)"
          ></el-button>
          <el-button
            type="text"
            icon="el-icon-caret-top"
            class="foldStatusButton button"
            v-show="buttonShowMore"
            @click="foldContentItem($event)"
          ></el-button>
        </div>
      </div>
      <div class="block-left-content" v-else>
        <div class="content-box">
          <alarm-rate
            class="content-box-item"
            :content-data="countyLevelData.exceedStandardsByMonth"
          ></alarm-rate>
        </div>
        <div class="content-box">
          <emission-reduce-six
            class="unfoldable-content-box-item"
            :content-data="countyLevelData.redEmissionsByMonth"
          ></emission-reduce-six>
        </div>
        <div class="large-content-box">
          <alarm-data
            class="unfoldable-content-box-item"
            :county-level-data="countyLevelData"
          ></alarm-data>
        </div>
      </div>
      <div class="block-center-content">
        <center
          :board-list="boardList"
          :sum-data="sumData"
          :online-device="onlineDeviceData"
          :top-list="allTopList"
          :content-data="dataObj"
          :county-level="countyLevelData"
          @cityChange="getAreaCode"></center>
      </div>
      <div class="block-right-content">
        <quick-entry></quick-entry>
        <region-compare
          v-if="dataObj.level !== 'district' &dataObj.level !== '3'"
          :online-device="onlineDeviceData"
          :top-list="allTopList"
        ></region-compare>
        <region-compare-dis
          v-else
          :county-level-data="countyLevelData"
        ></region-compare-dis>
      </div>
    </div>
  </div>
</template>

<script>
import topHeader from "./topHeader";
import onlineRate from "./onlineRate";
import reachRate from "./reachRate";
import installNumber from "./installNumber";
import emissionReduce from "./emissionReduce";
import alarmRate from "./alarmRate";
import emissionReduceSix from "./emissionReduceSix";
import alarmData from "./alarmData";
import quickEntry from "./quickEntry";
import quickEntryDis from "./quickEntry_dis";
import regionCompare from "./regionCompare";
import regionCompareDis from "./regionCompare_dis";

import center from "./center";
import {isEmptyObj, stg} from "@/util/index";
import {mapState} from 'vuex'

export default {
  name: "DataView",
  components: {
    topHeader,
    onlineRate,
    reachRate,
    installNumber,
    emissionReduce,
    alarmRate,
    alarmData,
    emissionReduceSix,
    quickEntry,
    quickEntryDis,
    regionCompare,
    regionCompareDis,
    center,
  },
  data() {
    return {
      timer: null, // 时间对象
      postdata: {
        WithSumData: true, //是否返回汇总数据,
        WithOnlineDevice: true, //返回在线设备,
        WithTopList: true, // 返回top5
      },
      dataObj: {
        online: [],
        compliance: [],
        device: [], // 安装数
        redEmissions: [], // 减排累计
        exceedStandardsByMonth: [],
        alarmData: [],
        redEmissionsByMonth: [],
        allOnlineRate: [], // 昨日在线率(所有)
        allOnlineRateIntime: [],
        allCompliance: [] // 昨日达标率(所有)
      },
      buttonShowMore: false,
      currentKey: 0,
      bigScreenLoading: false,
      boardList: {},
      sumData: {},
      allTopList: {},
      onlineDeviceData: {},
      countyLevelData: {},
      loading: true
    };
  },
  mounted() {
    this.postdata.AreaCode = stg().getItem("areaCode");
    this.postdata.level = stg().getItem("level");
    this.netGetData(true);
  },
  computed: {
    ...mapState(['boardDataStack'])
  },
  methods: {
    // 返回上一级
    backTop() {
      this.bigScreenLoading = true
      const len = this.boardDataStack.length
      if (len > 1) {
        this.postdata.AreaCode = this.boardDataStack[len - 2].code
        this.postdata.level = this.boardDataStack[len - 2].level
        this.$store.commit('popBoardDataStack')
        console.log(this.boardDataStack)
        this.netGetData(false)
        this.bigScreenLoading = false
      }
    },
    exactEnterpriseProportion(res, previousRes) {
      const chartsLabel = []
      const chartsValue = {
        Big: [],
        Normal: [],
        Small: [],
        maxV: 100
      }
      const t = []
      res.device.forEach((item) => {
        const r = previousRes.onlineDevice.device.find((vo) =>
          item.K.split('/').join('') === vo.K.split('/').join('')
        )
        if (r) {
          item.V =
            r.V == 0 ? 0 : ((item.V * 100) / r.V).toFixed(2) * 1
          item.N = r.N
          t.push(item)
        }
      })
      t.sort((o1, o2) => {
        if (o1.V < o2.V) {
          return 1
        } else if (o1.V > o2.V) {
          return -1
        } else {
          return 0
        }
      })
      t.forEach(e => {
        if (e.V > chartsValue.maxV) chartsValue.maxV = e.V
        chartsLabel.push(e.N)
        chartsValue.Big.push(e.BigNum)
        chartsValue.Normal.push(e.NormalNum)
        chartsValue.Small.push(e.SmallNum)
      })
      return {
        chartsLabel: chartsLabel,
        chartsValue: chartsValue
      }
    },
    unfoldContentItem(e) {
      this.$nextTick(function () {
        this.currentKey = new Date().getTime()
      })
      this.buttonShowMore = !this.buttonShowMore
      const currentNode = e.currentTarget.parentNode
      const parentNode = currentNode.parentNode
      let node = parentNode.firstChild
      currentNode.classList.add('large')
      while (node !== null) {
        if (node !== currentNode) {
          node.classList.add('fold')
        }
        node = node.nextSibling
      }
    },
    foldContentItem(e) {
      this.$nextTick(function () {
        this.currentKey = new Date().getTime()
      })
      this.buttonShowMore = !this.buttonShowMore
      const currentNode = e.currentTarget.parentNode
      const parentNode = currentNode.parentNode
      let node = parentNode.firstChild
      currentNode.classList.remove('large')
      while (node !== null) {
        if (node !== currentNode) {
          node.classList.remove('fold')
        }
        node = node.nextSibling
      }
    },
    /**
     * @description 获取数据
     */
    netGetData(type) {
      const param = Object.assign({}, this.postdata)
      param.areaId = parseInt(stg().getItem('areaId'), 10)
      this.boardList = {
        compliance: [],
        emissions: [],
        exceedStandards: [],
        online: [],
        redEmissions: [],
        redPm10: [],
        redPm25: [],
        redVocs: []
      }
      this.onlineDeviceData = {}
      this.countyLevelData = {}
      this.sumData = {
        complianceDeviceCount: 0,
        notComplianceDeviceCount: 0,
        onlineDeviceCount: 0,
        redEmissionAmount: 0,
        redPm10Amount: 0,
        redPm25Amount: 0,
        redVocsAmount: 0,
        totalDeviceInstalledCount: 0,
        totalLocaleCount: 0
      }
      this.allTopList = {}
      //boardTopList
      this.$post("admin/listBoardTopList", param).then(res => {
        this.boardList = res
      })
      //大屏OnlineDevice数据统计
      this.$post("admin/listBoardOnlineDevice", param).then(res => {
        this.onlineDeviceData = res
      })
      //大屏CountyLevel数据统计
      this.$post("admin/countyLevelData", this.postdata).then(res => {
        this.countyLevelData = res
      })
      // sumData中 onlineDeviceCountByDate数据
      this.$post("admin/listonlineDeviceCountByDate", this.postdata).then(res => {
        this.$set(this.sumData, 'complianceDeviceRatioByDate', res.sumData.complianceDeviceRatioByDate)
        this.$set(this.sumData, 'onlineDeviceCountByDate', res.sumData.onlineDeviceCountByDate)
        this.$set(this.sumData, 'monthGroupByDates', res.sumData.monthGroupByDates.reverse())
      })
      // sumData中 liststreetData数据
      this.$post("admin/liststreetData", this.postdata).then(res => {
        this.$set(this.sumData, 'streetData', res.sumData.streetData)
      })
      // sumData中 listsumDataTotal数据
      this.$post("admin/listsumDataTotal", this.postdata).then(res => {
        Object.assign(this.sumData, res.sumData)
      })
      //allTopList中compliance数据
      this.$post("admin/listComplianceTopList", this.postdata).then(res => {
        this.$set(this.allTopList, 'compliance', res.compliance)
      })
      //allTopList中online数据
      this.$post("admin/listOnlineTopList", this.postdata).then(res => {
        this.$set(this.allTopList, 'online', res.online)
      })
      // allTopList中online_intime数据
      this.$post("admin/listOnlineIntimeTopList", this.postdata).then(res => {
        this.$set(this.allTopList, 'online_intime', res.online_intime)
      })
      //allTopList中redEmissions数据
      this.$post("admin/listDeviceTopList", this.postdata).then(res => {
        this.$set(this.allTopList, 'device', res.device)
      })
      //allTopList中redEmissions数据
      this.$post("admin/listRedEmissionsTopList", this.postdata).then(res => {
        this.$set(this.allTopList, 'redEmissions', res.redEmissions)
      })
      //allTopList中listDeviceCountByHourTopList数据
      this.$post("admin/listDeviceCountByHourTopList", this.postdata).then(res => {
        this.$set(this.allTopList, 'onlineAndComplianceDeviceCountByHour', res.onlineAndComplianceDeviceCountByHour)
      })

      this.dataObj = {
        code: this.postdata.AreaCode,
        level: this.postdata.level,
      }
      if (type !== false) {
        this.$store.commit('pushBoardDataStack', this.dataObj)
      }
      const newkey = new Date().getTime()
      this.$nextTick(function () {
        this.currentKey = newkey
        this.currentKeyDistrict = newkey + 1
      })
    },

    getAreaCode(data) {
      if (data && data.code) {
        this.postdata.AreaCode = data.code + "";
        this.postdata.level = data.level + "";
      } else {
        this.postdata.AreaCode = stg().getItem("areaCode");
        this.postdata.level = stg().getItem("level");
      }
      this.netGetData();
    },
    handleLeftContentClick(type) {
      if (this.dataObj.level === 'country') return
      if (this.$store.state.districtInfoType !== type) {
        this.showMore = true
      } else {
        this.showMore = !this.showMore
      }
      this.$store.commit('setShowMoreDistrictInfo', {isShow: this.showMore, type: this.showMore ? type : 0})
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/common.scss";

#data-view {
  width: 100%;
  height: 100%;
  background-color: #011d24;
  color: #fff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;

  // #dv-full-screen-container {
  //   background-image: url('./img/bg.png');
  //   background-size: 100% 100%;
  //   box-shadow: 0 0 3px blue;
  //   background-color: #011D24;
  //   display: flex;
  //   flex-direction: column;
  //   box-sizing: border-box;
  //   overflow-y: auto;
  // }

  .main-content {
    flex: 1;
    display: flex;
    padding: 0 15px 10px;
    height: 90vh;

    .back {
      position: absolute;
      left: 22vw;
      top: 21vh;
      color: #eee;
      z-index: 99999;
      cursor: pointer;
    }
  }

  .foldStatusButton {
    color: #B2F2F6;
    padding: 0;
  }

  .block-left-content,
  .block-center-content,
  .block-right-content {
    display: flex;
    // padding: 0 10px 0 0;
  }

  .block-left-content,
  .block-right-content {
    width: 18vw;
    box-sizing: border-box;
    flex-direction: column;
  }

  .block-left-content {
    .content-box {
      height: 26vh;
    }

    .large-content-box {
      height: 52vh;
    }

    .content-box-item {
      height: calc(100% - 24px)
    }

    .unfoldable-content-box-item {
      height: 100%;
    }

    .button {
      width: 100%;
    }

    .large {
      height: 100%;
    }

    .fold {
      display: none;
    }
  }

  .block-right-content {
    > div {
      // margin-bottom: 16px;
      // background:linear-gradient(-39deg,rgba(12,116,214,0.04),rgba(19,50,121,0.04));
      height: 50%;
      // &:nth-of-type(1) {
      //   margin-bottom: 16px;
      //   // flex: 1;
      // }

      // &:nth-of-type(2) {
      //   // flex: 1.5;
      // }
    }
  }

  .block-center-content {
    width: 64vw;
    // flex: 1;
  }

  .content-item:hover {
    cursor: pointer;
  }
}

.section_title_span {
  font-size: 11px;
  margin-left: 3px;
}
</style>
