<template>
  <div class="quick_entry_dis">
    <dec-block-title>快捷入口</dec-block-title>
    <div class="quick_button_box">
      <el-button plain @click="toPage('/')">
        <div><i class="icon iconfont icondiqiu"></i>监管地图</div>
        <i class="el-icon-right el-icon--right"></i>
      </el-button>
      <el-button plain @click="toPage('/AlarmDetector')">
        <div><i class="icon iconfont iconjiance"></i>报警管理-监测</div>
        <i class="el-icon-right el-icon--right"></i>
      </el-button>
      <el-button plain @click="toPage('/Intime')">
        <div><i class="icon iconfont iconshishi"></i>实时管理</div>
        <i class="el-icon-right el-icon--right"></i>
      </el-button>
    </div>
    <ul>
      <li>
        <dv-active-ring-chart
          :config="enterInstallConfig"
          :key="key2"
          style="width: 120px; height: 120px"
        />
        <span>大中小型企业安装占比</span>
      </li>
    </ul>
  </div>
</template>

<script>
import decBlockTitle from "../decorations/blockTitle2";
// import { queryToMD5 } from "@/utils/md5_query"

export default {
  name: "quickEntry",
  components: {
    decBlockTitle,
  },
  props: ["contentData"],
  data() {
    return {
      config: {
        radius: "65%",
        activeRadius: "80%",
        lineWidth: 20,
        digitalFlopStyle: {
          fontSize: 14,
          fill: "#fff",
        },
        data: [],
      },
      enterInstallConfig: null,
      key2: 1,
    };
  },
  methods: {
    /**
     * @description 跳转页面
     */
    toPage(path) {
      this.$router.push({ path: path });
    },
    // 大中小型企业安装占比
    getenterInstallConfig() {
      const config = JSON.parse(JSON.stringify(this.config));
      const enterprise = this.contentData.onlineDevice.enterprise
        ? JSON.parse(JSON.stringify(this.contentData.onlineDevice.enterprise))
        : [];
      config.data = enterprise.map((item) => {
        item.value = item.V;
        item.name = item.K + "型";
        return item;
      });
      this.enterInstallConfig = config;
      this.key2++;
    },
  },
  watch: {
    contentData: {
      handler: function (data) {
        if (
          data.onlineDevice.enterprise &&
          data.onlineDevice.enterprise.length
        ) {
          this.getenterInstallConfig();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.quick_entry_dis {
  .quick_button_box {
    padding: 10px 15px;

    button,
    button:hover,
    button:focus {
      margin: 0 0 16px;
      background-color: transparent;
      border-color: #41d0d2;
      border-radius: 4px;
      color: #41d0d2;
      width: 192px;
      text-align: left;
      height: 34px;
      font-size: 14px;
      padding: 0 14px;
      line-height: 34px;

      span {
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          display: flex;
          align-items: center;

          .iconfont {
            font-size: 20px;
            margin-right: 10px;
          }
        }

        > .el-icon-right {
          font-size: 20px;
        }
      }
    }

    button:hover {
      opacity: 0.6;
    }
  }
  ul,
  li {
    padding: 0;
    margin: 0;
  }
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > span {
        font-size: 13px;
        color: #b2f2f6;
        text-align: center;
      }
    }
  }
}
</style>
