<template>
  <div class="region_compare" v-loading="loading">
    <dec-block-title>区域数据对比</dec-block-title>
    <ul>
      <li>
        <dv-active-ring-chart
          :key="key1"
          :config="installNumConfig"
          style="width: 120px; height: 120px"
        />
        <span>安装数量占比</span>
      </li>
      <li>
        <dv-active-ring-chart
          :config="enterInstallConfig"
          :key="key2"
          style="width: 120px; height: 120px"
        />
        <span>大中小型企业安装占比</span>
      </li>
      <li>
        <dv-active-ring-chart
          :key="key3"
          :config="emissionRateConfig"
          style="width: 120px; height: 120px"
        />
        <span>油烟排放达标率</span>
      </li>
      <li>
        <dv-active-ring-chart
          :key="key4"
          :config="reduceRateCofnig"
          style="width: 120px; height: 120px"
        />
        <span>油烟减排贡献率</span>
      </li>
    </ul>
  </div>
</template>

<script>
import decBlockTitle from "../decorations/blockTitle2";
// import { queryToMD5 } from "@/utils/md5_query"

export default {
  name: "regionCompare",
  components: {
    decBlockTitle,
  },
  props: ["onlineDevice", "topList"],
  data() {
    return {
      config: {
        radius: "65%",
        activeRadius: "80%",
        lineWidth: 20,
        digitalFlopStyle: {
          fontSize: 14,
          fill: "#fff",
        },
        data: [],
      },
      installNumConfig: null,
      enterInstallConfig: null,
      emissionRateConfig: null,
      reduceRateCofnig: null,
      key1: 1,
      key2: 1,
      key3: 1,
      key4: 1,
      loading: true
    };
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      if (!this.onlineDevice || !this.topList)
        return
      this.getinstallNumConfig();
      this.getenterInstallConfig();
      this.getemissionRateConfig();
      this.getreduceRateCofnig();
      this.loading = false
    },
    // 安装数量占比
    getinstallNumConfig() {
      // window.addEventListener("resize", (e) => {
      // this.$nextTick(() => {
      const config = JSON.parse(JSON.stringify(this.config));
      const device = this.onlineDevice.device
        ? JSON.parse(JSON.stringify(this.onlineDevice.device))
        : [];
      config.data = device.slice(0, 5).map((item) => {
        item.value = item.V;
        item.name = item.N;
        return item;
      });
      if (device.length > 5) {
        config.data.push({
          name: "其他",
          value: device
            .filter((item, index) => index > 4)
            .reduce((sum, cur) => {
              return sum + cur.V;
            }, 0),
        });
      }
      this.installNumConfig = config;
      this.key1++;
      // });
      // });
    },
    // 大中小型企业安装占比
    getenterInstallConfig() {
      const config = JSON.parse(JSON.stringify(this.config));
      const enterprise = this.onlineDevice.enterprise
        ? JSON.parse(JSON.stringify(this.onlineDevice.enterprise))
        : [];
      config.data = enterprise.map((item) => {
        item.value = item.V;
        item.name = item.K + "型";
        return item;
      });
      this.enterInstallConfig = config;
      this.key2++;
    },
    // 油烟排放达标率
    getemissionRateConfig() {
      const config = JSON.parse(JSON.stringify(this.config));
      const compliance = this.topList.compliance
        ? JSON.parse(JSON.stringify(this.topList.compliance))
        : [];
      config.data = compliance.map((item) => {
        item.value = item.V;
        item.name = item.N;
        return item;
      });
      this.emissionRateConfig = config;
      this.key3++;
    },
    // 油烟减排贡献率
    getreduceRateCofnig() {
      const config = JSON.parse(JSON.stringify(this.config));
      const redEmissions = this.topList.redEmissions
        ? JSON.parse(JSON.stringify(this.topList.redEmissions))
        : [];
      config.data = redEmissions.map((item) => {
        item.value = item.V;
        item.name = item.N;
        return item;
      });
      this.reduceRateCofnig = config;
      this.key4++;
    },
  },
  watch: {
    onlineDevice: {
      handler: function () {
        this.init()
      }
    },
    topList: {
      handler: function () {
        this.init()
      }
    },
  },
};
</script>

<style lang="scss">
.region_compare {
  width: 100%;
  // height: 33%;
  box-sizing: border-box;

  ul,
  li {
    padding: 0;
    margin: 0;
  }

  ul {
    height: calc(100% - 45px);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      > span {
        font-size: 13px;
        color: #b2f2f6;
        text-align: center;
      }
    }
  }
}
</style>
