<template>
  <div id="top-header">
    <img class="logo" src="./img/logo.png" alt="logo" />
    <div class="header_center">
      <img src="./img/line.png" alt="" />
    </div>
    <div class="header_right">
      <div class="time_box">
        <div>
          <span>{{ currentTime.week }}</span>
          <span>{{ currentTime.date }}</span>
        </div>
        <h2>{{ currentTime.time }}</h2>
      </div>
      <el-button class="btn_enter_sys" type="text" @click="returnIndex">
        <i class="icon iconfont iconjinru"></i>&nbsp;&nbsp;进入系统
      </el-button>
      <el-dropdown class="dropdown_admin_box" @command="handleCommand" trigger="click">
        <span class="el-dropdown-link">
          <i class="fa fa-user-circle-o" aria-hidden="true"></i> {{ user.Name }}<i class="el-icon-caret-bottom"></i>
        </span>
        <el-dropdown-menu class="dropdown_admin_menu" slot="dropdown">
          <el-dropdown-item command="logout">退出系统</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  removeToken,
  getUserInfo
} from '@/util/index'

export default {
  name: 'TopHeader',
  data() {
    return {
      currentTime: {
        week: '',
        time: '',
        date: ''
      },
      timer: null,
      user: {
        Name: ''
      }
    }
  },
  mounted() {
    moment.locale('zh-cn')
    this.timer = setInterval(() => {
      this.currentTime.week = moment().format('dddd')
      this.currentTime.time = moment().format('LTS')
      this.currentTime.date = moment().format('L')
    }, 1000)
    this.user = getUserInfo()
  },
  beforeDestroy() {
    // this.timer = null
    clearInterval(this.timer)
  },
  methods: {
    // 返回首页
    returnIndex() {
      this.$router.push({ path: '/' })
    },

    /**
     * @description 退出系统
     */
    handleCommand(command) {
      if (command === 'logout') {
        this.$confirm('确认退出吗?', '提示', {}).then(res => {
            // this.$post('logoutAction').then(data => {})
          removeToken()

          this.$store.commit('clear')
          this.$router.push({
            name: 'login'
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
#top-header {
  position: relative;
  width: 100%;
  height: 8vh;
  display: flex;
  // justify-content: space-between;
  flex-shrink: 0;
  padding: 24px 24px 16px;
  align-items: center;
  box-sizing: border-box;
  color: #41D0D2;

  .logo {
    width: 18%;
    margin-right: 15px;
  }

  .header_center {
    width: 47%;
    margin-right: 30px;
    img {
      width: 100%;
    }
  }

  .header_right {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .time_box {
      display: flex;
      align-items: center;
      font-family: fantasy;
      line-height: 1.3;
      width: 185px;
      h2 {
        font-size: 30px;
        font-weight: normal;
        margin: 0 10px 0;
      }

      div {
        display: flex;
        flex-direction: column;
        font-size: 11px;
      }
    }

    .btn_enter_sys {
      color: #41D0D2;
      font-size: 14px;

      span {
        line-height: 26px;
        display: flex;

        .iconfont {
          font-size: 26px;
          line-height: 1;
          margin-right: 8px;
        }
      }
    }

    .dropdown_admin_box {
      color: #41D0D2;
      .fa {
        font-size: 26px;
        margin-right: 7px;
      }

      .el-dropdown-link {
        display: flex;
      }

      .el-dropdown-link,
      .el-icon-caret-bottom {
        line-height: 26px;
      }

      .el-icon-caret-bottom {
        margin-left: 10px;
      }
    }
  }
}

.dropdown_admin_menu {
  background-color: #03232A;
  border-color: #24FFFC;
  border-radius: 3px;

  .el-dropdown-menu__item {
    color: #24FFFC;
  }
  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: rgba($color: #24FFFC, $alpha: 0.1);
    color: #24FFFC;
  }

  &[x-placement^=bottom] .popper__arrow {
    border-bottom-color: #24FFFC;
  }
  &[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: #03232A;
  }
}
</style>
