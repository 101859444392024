<template>
  <div class="reach_rate_page">
    <dec-block-title>近六个月油烟减排量</dec-block-title>
    <dv-charts :key="key" :option="option" />
  </div>
</template>

<script>
import decBlockTitle from '../decorations/blockTitle2'
// import { queryToMD5 } from "@/utils/md5_query"

export default {
  name: 'emissionReduceSix',
  components: {
    decBlockTitle
  },
  props: {
    contentData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      key: 1,
      option: {}
    }
  },
  watch: {
    'contentData': {
      handler: function(data) {
        if (!data) {
          return false
        }
        if (data.length === 0) {
          this.option = {}
        } else {
          this.key++;
          this.option = {
            // color: '#fff',
            xAxis: {
              min: 0,
              axisLine: {
                style: {
                  stroke: '#324D4D',
                  lineDash: [5, 2]
                }
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                style: {
                  fontSize: 9,
                  fill: '#D0D0D0'
                }
              },
              splitLine: {
                stroke: 'red',
                lineWidth: 1
              },
              data: data.map(item => {
                return item.CalculateMonth
              })
            },
            yAxis: {
              min: 0,
              data: 'value',
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                style: {
                  fontSize: 9,
                  fill: '#D0D0D0'
                }
              },
              splitLine: {
                style: {
                  stroke: '#324D4D',
                  lineWidth: 1,
                  lineDash: [5, 2]
                }
              }
            },
            series: [
              {
                xAxisIndex: 0,
                yAxisIndex: 0,
                data: data.map(item => {
                  return item.RedEmissions
                }),
                type: 'bar',
                barWidth: 20,
                barStyle: {
                  fill: '#24FFFC'
                }
              }
            ],
            grid: {
              left: 30,
              top: 20,
              right: 0,
              bottom: 20
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.reach_rate_page {
  width: 100%;
  // height: 33%;
  box-sizing: border-box;

  .dv-charts-container {
    height: calc(100% - 45px);
  }
}
</style>
